import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { dataApi } from "../../api/api";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Form, Input } from "antd";
import "./Login.css";
import StduyTrekk from "../../assets/studdytrekk.png";
const Login = () => {
    const navigate = useNavigate();
    // admin@admin.com
    // admin
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const handleSubmit = async (e) => {
        var response = await dataApi.login(username, password);

        if (response === 200) {
            var res = await dataApi.getStudentByToken(localStorage.getItem("token"));
            localStorage.setItem("user", JSON.stringify(res.data));

            if (JSON.parse(localStorage.getItem("user")).role === "ADMIN") {
                navigate("/dashboard");
            } else {
                console.log("Du måste vara Admin för att logga in!");
            }
        } else {
            console.log(`Något gick fel! Statuskod: ${response}`);
        }
    };

    return (
        <div className="wrapper">
            <div className="login-wrapper">
                <div className="login-logo" style={{ backgroundImage: `url(${StduyTrekk})` }}></div>

                <Form
                    name="normal_login"
                    className="login-form"
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={handleSubmit}
                >
                    <Form.Item
                        name="username"
                        rules={[
                            {
                                required: true,
                                message: "Skriv in ditt användarnamn",
                            },
                        ]}
                        onChange={(e) => setUsername(e.target.value)}
                    >
                        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Användarnamn" />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: "Skriv in ditt lösenord",
                            },
                        ]}
                        onChange={(e) => setPassword(e.target.value)}
                    >
                        <Input prefix={<LockOutlined className="site-form-item-icon" />} type="password" placeholder="Lösenord" />
                    </Form.Item>

                    <Form.Item>
                        <Button htmlType="submit" className="login-form-button">
                            Logga in
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};

export default Login;
