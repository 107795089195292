import { Button, Form, Input, Checkbox, Alert, Divider, Upload } from "antd";
import React, { useState, useEffect } from "react";
import { dataApi } from "../../api/api";
import { convertToSwedish } from "../../shared/SharedMethods";
const { Dragger } = Upload;
const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};

const AddArchiveLink = () => {
    const [form] = Form.useForm();
    const [visible, setMessageVisible] = useState(false);
    const [message, setMessage] = useState("");

    const [categories, setCategories] = useState([]);
    const [enums, setEnums] = useState([]);

    const [title, setTitle] = useState();
    const [description, setDescription] = useState();
    const [image, setImage] = useState();
    const [url, setUrl] = useState();

    const onHandleTitleChange = (e) => {
        setTitle(e.target.value);
    };
    const onHandleDescriptionChange = (e) => {
        setDescription(e.target.value);
    };
    const onHandleImageChange = (e) => {
        setImage(e.target.value);
    };
    const onHandleUrlChange = (e) => {
        setUrl(e.target.value);
    };

    const resetStates = () => {
        setTitle("");
        setDescription("");
        setImage("");
        setUrl("");
        setEnums([]);
    };

    useEffect(() => {
        loadCategories();
    }, []);

    const loadCategories = async () => {
        var response = await dataApi.getAllCategories();
        setCategories(response.data);
    };

    const onClick = (e) => {
        e.target.checked ? enums.push(e.target.value) : enums.splice(enums.indexOf(e.target.value), 1);
    };

    const onFinish = async () => {
        const archiveLink = {
            title,
            description,
            image,
            url,
            categories: enums,
        };
        var response = await dataApi.addArchiveLink(archiveLink);
        console.log(response);
        handleSetMessage(title);
        form.resetFields();
        resetStates();
    };

    const handleCloseMessage = () => {
        setMessageVisible(false);
    };

    const handleSetMessage = (title) => {
        setMessage(`Arkivlänk: ${title} är nu sparad!`);
        setMessageVisible(true);
    };

    return (
        <div style={{ width: "100%" }} className="table-list">
            <h2 style={{ fontWeight: "bolder", fontSize: "2rem" }}>Lägg till Arkivlänk</h2>
            <Divider />
            <Form form={form} {...layout} name="nest-messages" onFinish={onFinish}>
                <Form.Item label="Titel" name="title" value={title} onChange={onHandleTitleChange}>
                    <Input style={{ width: 498 }} />
                </Form.Item>

                <Form.Item name="description" label="Beskrivning" value={description} onChange={onHandleDescriptionChange}>
                    <Input.TextArea style={{ width: 498 }} />
                </Form.Item>

                <Form.Item name="url" label="Länk" value={url} onChange={onHandleUrlChange}>
                    <Input.TextArea style={{ width: 498 }} />
                </Form.Item>

                <Form.Item label="Kategorier" name="categories">
                    {categories.map((x) => (
                        <Checkbox onChange={onClick} key={x.id} value={x.categoryEnum}>
                            {convertToSwedish(x.categoryEnum)}
                        </Checkbox>
                    ))}
                </Form.Item>

                <Form.Item label="Bild" name="image" value={image} onChange={onHandleImageChange}>
                    {/* <Upload.Dragger name="files" action="/upload.do" style={{ width: 498 }}>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">Klick or dra fil</p>
                        <p className="ant-upload-hint">*JPG, JPNG, PNG</p>
                    </Upload.Dragger> */}
                    <Input style={{ width: 498 }} placeholder="Ladda upp en bild" />
                </Form.Item>

                <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
                    <Button type="primary" htmlType="submit">
                        Spara
                    </Button>
                </Form.Item>
            </Form>
            <div>{visible ? <Alert message={message} type="success" closable afterClose={handleCloseMessage} /> : null}</div>
        </div>
    );
};

export default AddArchiveLink;
