import axios from "axios";

let API_BASE_URL = "https://studytrekk.demo.hexastudio.se/api/";
// let API_BASE_URL = "http://studytrekk.app.dev.hexastudio.se/api/";
// let API_BASE_URL = "http://localhost:8080/api/";
const service = axios.create({
    baseURL: API_BASE_URL,
    responseType: "json",
    timeout: 30000,
});

const tokenInterceptor = (config) => {
    // Set token from localstorage
    const token = localStorage.getItem("token");

    if (token !== null) {
        config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
};

class DataAPI {
    constructor() {
        this.instance = service;
        this.instance.interceptors.request.use((config) => tokenInterceptor(config));
    }

    async login(username, password) {
        var statusCode;

        await this.instance
            .post("auth", {
                username,
                password,
            })
            .then((res) => {
                if (res.status === 200) {
                    localStorage.setItem("token", res.data.token);
                }
                statusCode = res.status;
            })
            .catch((err) => {
                console.log(err);
                statusCode = err.response.status;
            });
        return statusCode;
    }

    /* STUDENT */
    async getAllStudents() {
        return this.instance.get("student/all");
    }

    async getStudentById(studentId) {
        return this.instance.get(`admin/student/${studentId}`);
    }

    async getStudentByToken(userToken) {
        return this.instance.get(`auth`);
    }

    async addStudent(student) {
        return this.instance.post("admin/student", student);
    }

    async updateStudent(studentId, student) {
        return this.instance.patch(`admin/student/${studentId}`, student);
    }

    async deleteStudent(studentId) {
        return this.instance.delete(`admin/student/${studentId}`);
    }

    /* TECHNIQUE */
    async getAllTechniques() {
        return this.instance.get("technique/all");
    }

    async getTechniqueById(techniqueId) {
        return this.instance.get(`technique/${techniqueId}`);
    }

    async addTechnique(title, description, image, enums) {
        return this.instance.post("admin/technique/", { title, description, image, enums });
    }

    async updateTechnique(id, technique) {
        return this.instance.patch(`admin/technique/${id}`, technique);
    }

    async deleteTechnique(techniqueId) {
        return this.instance.delete(`admin/technique/${techniqueId}`);
    }

    /* CUSTOM TECHNIQUE */
    async getAllCustomTechniques() {
        return this.instance.get("admin/customtechniques");
    }

    /* STATEMENT */
    async getAllStatements() {
        return this.instance.get("statement/all");
    }

    async getStatementById(statementId) {
        return this.instance.get(`statement/${statementId}`);
    }

    async updateStatement(statementId, statement) {
        return this.instance.patch(`admin/statement/${statementId}`, statement);
    }

    async addStatement(statement) {
        return this.instance.post("admin/statement", statement);
    }

    async deleteStatement(statementId) {
        return this.instance.delete(`/admin/statement/${statementId}`);
    }

    /* CATEGORY */
    async getAllCategories() {
        return this.instance.get("category/all");
    }

    async getCategoryById(categoryId) {
        return this.instance.get(`admin/category/${categoryId}`);
    }

    /* ARCHIVE LINK */
    async getAllArchiveLinks() {
        return this.instance.get("archivelink/list");
    }

    async getArchiveLinkById(archiveLinkId) {
        return this.instance.get(`archivelink/${archiveLinkId}`);
    }

    async addArchiveLink(archiveLink) {
        return this.instance.post("admin/archivelink", archiveLink);
    }

    async updateArchiveLink(archiveLinkId, archiveLink) {
        return this.instance.patch(`admin/archivelink/${archiveLinkId}`, archiveLink);
    }

    async deleteArchiveLink(archiveLinkId) {
        return this.instance.delete(`admin/archivelink/${archiveLinkId}`);
    }
}

const dataApi = new DataAPI();

export { dataApi };
