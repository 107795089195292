import { Button, Form, Input, Checkbox, Divider, Spin, Modal, InputNumber, Space } from "antd";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { dataApi } from "../../api/api";
import { convertToSwedish } from "../../shared/SharedMethods";

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};

const UpdateTechnique = () => {
    const [form] = Form.useForm();
    const params = useParams();
    const navigate = useNavigate();
    const [categories, setCategories] = useState([]);
    const [currentEnums, setCurrentEnums] = useState([]);
    const [techniqueScore, setTechniqueScore] = useState(0);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [image, setImage] = useState("");
    const [loading, setLoading] = useState(true);
    const [selectedCategory, setSelectCategory] = useState();
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [cateWeight, setCateWeight] = useState();
    const onHandleTitleChange = (e) => {
        setTitle(e.target.value);
    };
    const onHandleDescriptionChange = (e) => {
        setDescription(e.target.value);
    };
    const onHandleImageChange = (e) => {
        setImage(e.target.value);
    };

    useEffect(() => {
        getTechnique(params.id);
    }, [params.id]);

    useEffect(() => {
        loadCategories();
    }, []);

    const loadCategories = async () => {
        var response = await dataApi.getAllCategories();
        setCategories(response.data);
    };

    const handleUpdateTechnique = async () => {
        const enumsId = [];
        const uniqEnums = Array.from(new Set(currentEnums));
        uniqEnums.forEach((r) => enumsId.push(r));

        const technique = {
            id: params.id,
            title,
            description,
            image,
            techniqueScore,
            categories: enumsId,
        };

        await dataApi.updateTechnique(params.id, technique);
        //Om response = OK
        navigate("/listTechniques");
        //Annars: Felmeddelande
    };

    const getTechnique = async (id) => {
        const response = await dataApi.getTechniqueById(id);
        const data = await response.data;
        form.setFieldsValue({
            title: data.title,
            description: data.description,
            image: data.image,
            techniqueScore: data.techniqueScore,
        });

        setTitle(data.title);
        setDescription(data.description);
        setImage(data.image);
        setTechniqueScore(data.techniqueScore);
        data.categories.forEach((category) => {
            setCurrentEnums((r) => [...r, category]);
        });
        setTimeout(() => {
            setLoading(false);
        }, 150);
    };

    const onClick = (e) => {
        if (e.target.checked) {
            // setCurrentEnums((r) => [...r, e.target.value]);
            setSelectCategory(e.target.value);
            setIsOpenModal(true);
        } else {
            const index = currentEnums.findIndex((r) => r.id === e.target.value.id);
            if (index !== -1) {
                currentEnums.splice(index, 1);
            }
        }
    };

    const checkedIfChecked = (category) => {
        const isCheck = currentEnums.find((r) => r.id === category);
        return isCheck;
    };
    const addCategory = () => {
        const obj = selectedCategory;
        obj.weight = Number(cateWeight);
        console.log(obj);
        setCurrentEnums((r) => [...r, obj]);
        setIsOpenModal(false);
    };
    const checkWeight = (v) => {
        const cateWeight = currentEnums.find((r) => r.id === v);
        if (cateWeight !== undefined) return cateWeight.weight;
    };
    return (
        <>
            <Modal title="Kategori Vikt" visible={isOpenModal} onCancel={() => setIsOpenModal(false)} onOk={addCategory}>
                <Input onChange={(e) => setCateWeight(e.target.value)} />
            </Modal>

            {loading ? (
                <Spin />
            ) : (
                <div style={{ width: "100%" }} className="table-list">
                    <h2 style={{ fontWeight: "bolder", fontSize: "2rem" }}>Uppdatera Teknik</h2>
                    <Divider />
                    <Form form={form} {...layout} name="nest-messages" onFinish={handleUpdateTechnique}>
                        <Form.Item label="Titel" name="title" value={title} onChange={onHandleTitleChange}>
                            <Input style={{ width: 498 }} />
                        </Form.Item>

                        <Form.Item label="Beskrivning" name="description" value={description} onChange={onHandleDescriptionChange}>
                            <Input.TextArea style={{ width: 498, height: 160 }} />
                        </Form.Item>
                        <Form.Item label="Kategorier">
                            {categories
                                .filter((v, i, a) => a.findIndex((v2) => v2.id === v.id) === i)
                                .map((x) => (
                                    <Checkbox onChange={onClick} key={x.id} value={x} defaultChecked={checkedIfChecked(x.id)}>
                                        <Space size={2}>
                                            {convertToSwedish(x.categoryEnum)}
                                            {checkWeight(x.id) !== undefined && <span> - vikt: {checkWeight(x.id)}</span>}
                                        </Space>
                                    </Checkbox>
                                ))}
                        </Form.Item>

                        <Form.Item label="Score" name="techniqueScore" value={techniqueScore} onChange={(e) => setTechniqueScore(e.target.value)}>
                            <Input style={{ width: 498 }} type="number" />
                        </Form.Item>
                        <Form.Item label="Bild" name="image" value={image} onChange={onHandleImageChange}>
                            <Input style={{ width: 498 }} />
                        </Form.Item>

                        <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
                            <Button type="primary" htmlType="submit">
                                Uppdatera
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            )}
        </>
    );
};

export default UpdateTechnique;
