import { Button, Divider, Form, Input, Select } from "antd";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { dataApi } from "../../api/api";
import { convertToSwedish } from "../../shared/SharedMethods";

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};

const UpdateStatement = () => {
    const [form] = Form.useForm();
    const { Option } = Select;
    const params = useParams();
    const navigate = useNavigate();

    const [categories, setCategories] = useState([]);
    const [description, setDescription] = useState("");
    const [category, setCategory] = useState("");

    const onHandleDescriptionChange = (e) => {
        setDescription(e.target.value);
    };

    useEffect(() => {
        getStatement(params.id);
    }, [params.id]);

    useEffect(() => {
        loadCategories();
    }, []);

    const loadCategories = async () => {
        var response = await dataApi.getAllCategories();
        setCategories(response.data);
    };

    const onCategoryChange = (value) => {
        setCategory(value);
    };

    const handleUpdateStatement = async (e) => {
        const statement = {
            description,
            categoryEnum: category,
        };

        await dataApi.updateStatement(params.id, statement);
        //Om response = OK
        navigate("/listStatements");
        //Annars: Felmeddelande
    };

    const getStatement = async (id) => {
        const response = await dataApi.getStatementById(id);

        const data = response.data;

        form.setFieldsValue({
            description: data.description,
            category: data.categoryEnum,
        });

        setDescription(data.description);
        setCategory(data.categoryEnum);
    };

    return (
        <>
            <div style={{ width: "100%" }} className="table-list">
                <h2 style={{ fontWeight: "bolder", fontSize: "2rem" }}>Uppdatera Påstående</h2>
                <Divider />
                <Form form={form} {...layout} name="nest-messages" onFinish={handleUpdateStatement}>
                    <Form.Item label="Beskrivning" name="description" value={description} onChange={onHandleDescriptionChange}>
                        <Input style={{ width: 498 }} />
                    </Form.Item>

                    <Form.Item name="category" label="Kategori">
                        <Select placeholder="Välj en kategori" onChange={onCategoryChange} allowClear style={{ width: 498 }}>
                            {categories.map((x) => (
                                <Option value={x.categoryEnum} key={x.id}>
                                    {convertToSwedish(x.categoryEnum)}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>

                    <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
                        <Button type="primary" htmlType="submit">
                            Uppdatera
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </>
    );
};

export default UpdateStatement;
