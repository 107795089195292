export const convertToSwedish = (str) => {
    switch (str) {
        case "FLEXIBILITY":
            return "Flexibilitet";
        case "PROCRASTINATION":
            return "Uppskjutande";
        case "COMMUNICATION":
            return "Kommunikation";
        case "WRITING":
            return "Skriva";
        case "READING":
            return "Läsa";
        case "SENSORY_OVERLOAD":
            return "Sinnesintryck";
        case "REVIEW_KNOWLEDGE":
            return "Redovisa Kunskap";
        case "IMPULSIVENESS":
            return "Reglera Impulsivitet";
        case "CONCENTRATION":
            return "Koncentration";
        case "TIME_MANAGEMENT":
            return "Tidsuppfattning";
        case "ORGANISATION":
            return "Planera";
        default:
            return 0;
    }
};
export const categoryColor = (str) => {
    switch (str) {
        case "FLEXIBILITY":
            return "#F4F1DE";
        case "PROCRASTINATION":
            return "#E07A5F";
        case "COMMUNICATION":
            return "#81B29A";
        case "WRITING":
            return "#F2CC8F";
        case "READING":
            return "#FFCDB2";
        case "SENSORY_OVERLOAD":
            return "#FFB4A2";
        case "REVIEW_KNOWLEDGE":
            return "#E5989B";
        case "IMPULSIVENESS":
            return "#A9C7C5";
        case "CONCENTRATION":
            return "#B5838D";
        case "TIME_MANAGEMENT":
            return "#D3D6FD";
        case "ORGANISATION":
            return "#9EA3F3";
        default:
            return 0;
    }
};
