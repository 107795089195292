import { Button, Divider, Form, Input } from "antd";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { dataApi } from "../../api/api";

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};

const UpdateStudent = () => {
    const [form] = Form.useForm();
    const params = useParams();
    const navigate = useNavigate();

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [pinCode, setPinCode] = useState("");

    const onHandleNameChange = (e) => {
        setName(e.target.value);
    };
    const onHandleEmailChange = (e) => {
        setEmail(e.target.value);
    };
    const onHandlePinCodeChange = (e) => {
        setPinCode(e.target.value);
    };

    useEffect(() => {
        getStudent(params.id);
    }, [params.id]);

    const handleUpdateStudent = async (e) => {
        const student = {
            name,
            email,
            pinCode,
        };

        var response = await dataApi.updateStudent(params.id, student);
        //Om response = OK
        navigate("/listStudents");
        //Annars: Felmeddelande
    };

    const getStudent = async (id) => {
        const response = await dataApi.getStudentById(id);

        const data = response.data;

        form.setFieldsValue({
            name: data.name,
            email: data.email,
            pinCode: data.pinCode,
        });

        setName(data.name);
        setEmail(data.email);
        setPinCode(data.pinCode);
    };

    return (
        <>
            <div style={{ width: "100%" }} className="table-list">
                <h2 style={{ fontWeight: "bolder", fontSize: "2rem" }}>Uppdatera Student</h2>
                <Divider />
                <Form form={form} {...layout} name="nest-messages" onFinish={handleUpdateStudent}>
                    <Form.Item label="Namn" name="name" value={name} onChange={onHandleNameChange}>
                        <Input style={{ width: 498 }} />
                    </Form.Item>

                    <Form.Item label="Email" name="email" value={email} onChange={onHandleEmailChange}>
                        <Input style={{ width: 498 }} />
                    </Form.Item>

                    <Form.Item label="Pinkod" name="pinCode" value={pinCode} onChange={onHandlePinCodeChange}>
                        <Input style={{ width: 498 }} />
                    </Form.Item>

                    <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
                        <Button type="primary" htmlType="submit">
                            Uppdatera
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </>
    );
};

export default UpdateStudent;
