import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { Table, Space, Popconfirm, Button, Input, Divider, Row, Col } from "antd";
import { dataApi } from "../../api/api";
import { useNavigate } from "react-router-dom";
import { SearchOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { convertToSwedish } from "../../shared/SharedMethods";

const TechniqueList = () => {
    const [techniques, setTechniques] = useState([]);
    const [tempArr, setTempArr] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        loadTechniques();
    }, []);

    useEffect(() => {
        const addKeyToTechniques = () => {
            let tempArr = [];
            for (let i = 0; i < techniques.length; i++) {
                var categories = "";
                var categoryArray = techniques[i].categories;

                for (let j = 0; j < categoryArray.length; j++) {
                    if (j !== 0) {
                        categories += ", ";
                    }
                    categories += categoryArray[j].categoryEnum;
                }

                tempArr.push({
                    key: i,
                    id: techniques[i].id,
                    title: techniques[i].title,
                    description: techniques[i].description,
                    image: techniques[i].image,
                    score: techniques[i].techniqueScore,
                    categories: categories,
                });
            }

            setTempArr(tempArr);
        };

        addKeyToTechniques();
    }, [techniques]);

    const loadTechniques = async () => {
        var response = await dataApi.getAllTechniques();

        response.data.forEach((techniques) => {
            techniques.categories.forEach((category) => {
                category.categoryEnum = convertToSwedish(category.categoryEnum);
            });
        });

        setTechniques(response.data);
    };

    const handleUpdate = (e) => {
        navigate(`/updateTechnique/${e.id}`);
    };

    const handleDelete = async (e) => {
        // 1. Confirm delete-dialog
        // 2. Om ja:
        var response = await dataApi.deleteTechnique(e.id);
        console.log(response);
        loadTechniques();
        //    Annars: stäng dialog...
    };

    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const searchInput = useRef(null);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div
                style={{
                    padding: 8,
                }}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: "block",
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? "#1890ff" : undefined,
                }}
            />
        ),
        onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: "#ffc069",
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ""}
                />
            ) : (
                text
            ),
    });

    const columns = [
        {
            title: "Titel",
            dataIndex: "title",
            key: "title",
            width: "20%",
            sorter: (a, b) => {
                if (a.title < b.title) {
                    return -1;
                }
                if (a.title > b.title) {
                    return 1;
                }
                return 0;
            },
            ...getColumnSearchProps("title"),
        },
        {
            title: "Beskrivning",
            dataIndex: "description",
            key: "description",
            ...getColumnSearchProps("description"),
            sorter: (a, b) => a.description.length - b.description.length,
            sortDirections: ["descend", "ascend"],
        },
        {
            title: "Score",
            dataIndex: "score",
            key: "score",
        },
        // {
        //     title: "Bild",
        //     dataIndex: "image",
        //     key: "image",
        //     ...getColumnSearchProps("image"),
        // },
        {
            title: "Kategorier",
            key: "categories",
            dataIndex: "categories",
            ...getColumnSearchProps("categories"),
        },
        {
            title: "Hantera",
            dataIndex: "actions",
            width: "5%",
            render: (_, record) => (
                <Row gutter={16} justify="space-between">
                    <Col>
                        <EditOutlined style={{ color: "#045C94" }} onClick={() => handleUpdate(record)} />
                    </Col>
                    <Col>
                        <Popconfirm title="Är du säker?" okText="Ja" cancelText="Nej" onConfirm={() => handleDelete(record)}>
                            <DeleteOutlined style={{ color: "#EB3E1B" }} />
                        </Popconfirm>
                    </Col>
                </Row>
            ),
        },
    ];
    return (
        <div style={{ width: "100%" }} className="table-list">
            <Row justify="space-between" align="middle">
                <Col>
                    <h1 style={{ fontWeight: "bolder", fontSize: "2rem" }}>Tekniker</h1>
                </Col>
                <Col>
                    <Button
                        className="add-btn"
                        type="primary"
                        onClick={() => {
                            navigate("/addTechnique");
                        }}
                    >
                        Lägg till
                    </Button>
                </Col>
            </Row>
            <Divider />
            <Table columns={columns} dataSource={tempArr} />
        </div>
    );
};

export default TechniqueList;
