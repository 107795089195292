import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { Table, Space, Button, Input, Divider, Row, Col } from "antd";
import { dataApi } from "../../api/api";
import { SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { convertToSwedish } from "../../shared/SharedMethods";

const CustomTechniqueList = () => {
    const [customTechniques, setCustomTechniques] = useState([]);
    const [tempArr, setTempArr] = useState([]);

    useEffect(() => {
        const loadCustomTechniques = async () => {
            var response = await dataApi.getAllCustomTechniques();
            var tempCategoryArray = [];
    
            response.data.forEach((customTechnique) => {
                customTechnique.categories.forEach((category) => {
                    category = convertToSwedish(category);
                    tempCategoryArray.push(category);
                });
                customTechnique.categories = tempCategoryArray;
                tempCategoryArray = [];
            });
            setCustomTechniques(response.data);
        };

        loadCustomTechniques();
    }, []);

    useEffect(() => {
        const addKeyToCustomTechniques = () => {
            let tempArr = [];
            for (let i = 0; i < customTechniques.length; i++) {
                var categories = "";
                var categoryArray = customTechniques[i].categories;

                for (let j = 0; j < categoryArray.length; j++) {
                    if (j !== 0) {
                        categories += ", ";
                    }
                    categories += categoryArray[j];
                }

                tempArr.push({
                    key: i,
                    id: customTechniques[i].id,
                    title: customTechniques[i].title,
                    description: customTechniques[i].description,
                    author: customTechniques[i].user,
                    categories: categories,
                });
            }

            setTempArr(tempArr);
        };

        addKeyToCustomTechniques();
    }, [customTechniques]);

    

    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const searchInput = useRef(null);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div
                style={{
                    padding: 8,
                }}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: "block",
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? "#1890ff" : undefined,
                }}
            />
        ),
        onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: "#ffc069",
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ""}
                />
            ) : (
                text
            ),
    });

    const columns = [
        {
            title: "Titel",
            dataIndex: "title",
            key: "title",
            width: "20%",
            sorter: (a, b) => {
                if (a.title < b.title) {
                    return -1;
                }
                if (a.title > b.title) {
                    return 1;
                }
                return 0;
            },
            ...getColumnSearchProps("title"),
        },
        {
            title: "Beskrivning",
            dataIndex: "description",
            key: "description",
            ...getColumnSearchProps("description"),
            sorter: (a, b) => a.description.length - b.description.length,
            sortDirections: ["descend", "ascend"],
        },
/*         {
            title: "Score",
            dataIndex: "score",
            key: "score",
        }, */
        // {
        //     title: "Bild",
        //     dataIndex: "image",
        //     key: "image",
        //     ...getColumnSearchProps("image"),
        // },
        {
            title: "Kategorier",
            key: "categories",
            dataIndex: "categories",
            ...getColumnSearchProps("categories"),
        },

        {
            title: "Skapare",
            dataIndex: "author",
            key: "author",
            width: "20%",
            sorter: (a, b) => {
                if (a.author < b.author) {
                    return -1;
                }
                if (a.author > b.author) {
                    return 1;
                }
                return 0;
            },
            ...getColumnSearchProps("author"),
        },
    ];
    return (
        <div style={{ width: "100%" }} className="table-list">
            <Row justify="space-between" align="middle">
                <Col>
                    <h1 style={{ fontWeight: "bolder", fontSize: "2rem" }}>Egna tekniker</h1>
                </Col>
            </Row>
            <Divider />
            <Table columns={columns} dataSource={tempArr} />
        </div>
    );
};

export default CustomTechniqueList;
