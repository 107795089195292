import { ProfileOutlined, OneToOneOutlined, LogoutOutlined, UserOutlined, HomeOutlined, IdcardOutlined, LaptopOutlined } from "@ant-design/icons";
import { Menu, Button} from "antd";
import React from "react";
import "./navbar.css";
import { useLocation, useNavigate } from "react-router-dom";

function getItem(label, key, icon, children, type) {
    return {
        key,
        icon,
        children,
        label,
        type,
    };
}

const items = [
    getItem("Dashboard", "/dashboard", <HomeOutlined />),
    getItem("Studenter", "/listStudents", <UserOutlined />),
    getItem("Påstående", "/listStatements", <ProfileOutlined />),
    getItem("Tekniker", "/listTechniques", <OneToOneOutlined />),
    getItem("Egna tekniker", "/listCustomTechniques", <IdcardOutlined />),
    getItem("Länkarkiv", "/listArchiveLinks", <LaptopOutlined />),
    getItem("Logga ut", "/logout", <LogoutOutlined />),
];

const Navbar = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const onClick = (e) => {
        navigate(`${e.key}`);
    };

    return (
        <div className="sidebar">
            <div style={{ padding: "40px 40px 0 40px" }}>
                <div className="sidebar-logo" />
            </div>
            <Menu
                onClick={onClick}
                style={{
                    width: 296,
                    padding: "24px 0",
                }}
                defaultSelectedKeys={[`${location.pathname}`]}
                mode="inline"
                items={items}
            />
             <a href="https://studytrekk.demo.hexastudio.se/apk">
                    <Button>Ladda ner apk</Button>
                </a>
        </div>
    );
};

export default Navbar;
