import { Button, Form, Input, Checkbox, Divider } from "antd";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { dataApi } from "../../api/api";
import { convertToSwedish } from "../../shared/SharedMethods";

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};

const UpdateArchiveLink = () => {
    const [form] = Form.useForm();
    const params = useParams();
    const navigate = useNavigate();

    const [categories, setCategories] = useState([]);
    const [enums] = useState([]);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [image, setImage] = useState("");
    const [url, setUrl] = useState("");

    const onHandleTitleChange = (e) => {
        setTitle(e.target.value);
    };
    const onHandleDescriptionChange = (e) => {
        setDescription(e.target.value);
    };
    const onHandleImageChange = (e) => {
        setImage(e.target.value);
    };
    const onHandleUrlChange = (e) => {
        setUrl(e.target.value);
    };

    useEffect(() => {
        getArchiveLink(params.id);
    }, [params.id]);

    useEffect(() => {
        loadCategories();
    }, []);

    const loadCategories = async () => {
        var response = await dataApi.getAllCategories();
        setCategories(response.data);
    };

    const handleUpdateArchiveLink = async (e) => {
        const archiveLink = {
            title,
            description,
            image,
            url,
            categories: enums,
        };

        var response = await dataApi.updateArchiveLink(params.id, archiveLink);
        console.log(response);
        //Om response = OK
        navigate("/listArchiveLinks");
        //Annars: Felmeddelande
    };

    const getArchiveLink = async (id) => {
        const response = await dataApi.getArchiveLinkById(id);

        const data = response.data;

        form.setFieldsValue({
            title: data.title,
            description: data.description,
            image: data.image,
            url: data.url,
        });

        setTitle(data.title);
        setDescription(data.description);
        setImage(data.image);
        setUrl(data.url);
        data.categories.forEach((category) => {
            if (!enums.includes(category)) {
                enums.push(category);
            }
        });
    };

    const onClick = (e) => {
        e.target.checked ? enums.push(e.target.value) : enums.splice(enums.indexOf(e.target.value), 1);
    };

    const checkedIfChecked = (category) => {
        if (enums.includes(category)) {
            return true;
        }
        return false;
    };

    return (
        <div style={{ width: "100%" }} className="table-list">
            <h2 style={{ fontWeight: "bolder", fontSize: "2rem" }}>Uppdatera Arkivlänk</h2>
            <Divider />
            <Form form={form} {...layout} name="nest-messages" onFinish={handleUpdateArchiveLink}>
                <Form.Item label="Titel" name="title" value={title} onChange={onHandleTitleChange}>
                    <Input style={{ width: 498 }} />
                </Form.Item>

                <Form.Item label="Beskrivning" name="description" value={description} onChange={onHandleDescriptionChange}>
                    <Input.TextArea style={{ width: 498, height: 160 }} />
                </Form.Item>

                <Form.Item label="Länk" name="url" value={url} onChange={onHandleUrlChange}>
                    <Input.TextArea style={{ width: 498, height: 160 }} />
                </Form.Item>

                <Form.Item label="Kategorier" name="categories">
                    {categories.map((x) => (
                        <Checkbox onChange={onClick} key={x.id} value={x.categoryEnum} defaultChecked={checkedIfChecked(x.categoryEnum)}>
                            {convertToSwedish(x.categoryEnum)}
                        </Checkbox>
                    ))}
                </Form.Item>

                <Form.Item label="Bild" name="image" value={image} onChange={onHandleImageChange}>
                    <Input style={{ width: 498 }} />
                </Form.Item>

                <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
                    <Button type="primary" htmlType="submit">
                        Uppdatera
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default UpdateArchiveLink;
