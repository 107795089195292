import React, { useRef, useState, useEffect } from "react";
import { Table, Space, Popconfirm, Button, Input, Divider, Row, Col } from "antd";
import { dataApi } from "../../api/api";
import { useNavigate } from "react-router-dom";
/* import SearchBar from "../Student/Searchbar";
import { arraySearch } from "../functions/StudentArraySearch"; */
import { SearchOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";

const StudentList = () => {
    const [students, setStudents] = useState([]);
/*     const [search, setSearch] = useState(""); */
    const navigate = useNavigate();

    useEffect(() => {
        loadStudents();
    }, []);

    const loadStudents = async () => {
        var response = await dataApi.getAllStudents();
        setStudents(response.data);
    };

    const handleUpdate = (e) => {
        navigate(`/updateStudent/${e.id}`);
    };

    const handleDelete = async (e) => {
        var response = await dataApi.deleteStudent(e.id);
        console.log(response);
        loadStudents();
    };
/*     const [studentsearch, setStudentSearch] = useState(students);
    const [count, setCount] = useState(0); */
    // const [input, setInput] = useState('')

    // useEffect(()=>{
    //   console.log(count)
    //   handleOnChange()
    // }, [])

    // const handleOnChange = async (e) => {
    //   let value = e.target.value;
    //   if (value.length > 0 ) {
    //     let search = arraySearch(studentsearch, value);
    //     setStudentSearch(search)
    //     setCount(search.length)
    //   } else {
    //     setStudentSearch(students)
    //     setCount(students.length)
    //   }
    // }

    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const searchInput = useRef(null);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div
                style={{
                    padding: 8,
                }}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: "block",
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? "#1890ff" : undefined,
                }}
            />
        ),
        onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: "#ffc069",
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ""}
                />
            ) : (
                text
            ),
    });
    const columns = [
        {
            title: "Namn",
            dataIndex: "name",
            key: "name",
            sorter: (a, b) => {
                if (a.name < b.name) {
                    return -1;
                }
                if (a.name > b.name) {
                    return 1;
                }
                return 0;
            },
            ...getColumnSearchProps("name"),
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
            sorter: (a, b) => {
                if (a.email < b.email) {
                    return -1;
                }
                if (a.email > b.email) {
                    return 1;
                }
                return 0;
            },
            ...getColumnSearchProps("email"),
        },
        {
            title: "Hantera",
            dataIndex: "actions",
            width: "5%",
            render: (_, record) => (
                // <Space size="middle">
                // </Space>
                <Row gutter={16} justify="space-between">
                    <Col>
                        <EditOutlined style={{ color: "#045C94" }} onClick={() => handleUpdate(record)} />
                    </Col>
                    <Col>
                        <Popconfirm title="Är du säker?" okText="Ja" cancelText="Nej" onConfirm={() => handleDelete(record)}>
                            <DeleteOutlined style={{ color: "#EB3E1B" }} />
                        </Popconfirm>
                    </Col>
                </Row>
            ),
        },
    ];
    return (
        <div style={{ width: "100%" }} className="table-list">
            <Row justify="space-between" align="middle">
                <Col>
                    <h1 style={{ fontWeight: "bolder", fontSize: "2rem" }}>Studenter</h1>
                </Col>
                <Col>
                    <Button
                        className="add-btn"
                        type="primary"
                        onClick={() => {
                            navigate("/addStudent");
                        }}
                    >
                        Lägg till
                    </Button>
                </Col>
            </Row>
            <Divider />
            <Table columns={columns} dataSource={students} />
        </div>
    );
};

export default StudentList;
