import { Button, Form, Input, Select, Alert, Divider } from "antd";
import { useState, useEffect } from "react";
import { dataApi } from "../../api/api";
import { convertToSwedish } from "../../shared/SharedMethods";

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};
const { TextArea } = Input;
const AddStatement = () => {
    const [form] = Form.useForm();
    const [visible, setMessageVisible] = useState(false);
    const [message, setMessage] = useState("");
    const { Option } = Select;

    const [categories, setCategories] = useState([]);
    const [description, setDescription] = useState("");
    const [category, setCategory] = useState("");

    const onHandleDescriptionChange = (e) => {
        setDescription(e.target.value);
    };

    const resetStates = () => {
        setDescription("");
        setCategory("");
    };

    useEffect(() => {
        loadCategories();
    }, []);

    const loadCategories = async () => {
        var response = await dataApi.getAllCategories();
        setCategories(response.data);
    };

    const onFinish = async () => {
        const statement = {
            description,
            categoryEnum: category,
        };

        await dataApi.addStatement(statement);
        form.resetFields();
        resetStates();
        handleSetMessage();
    };

    const onCategoryChange = (value) => {
        setCategory(value);
    };

    const handleCloseMessage = () => {
        setMessageVisible(false);
    };

    const handleSetMessage = () => {
        setMessage(`Påstående är nu sparat!`);
        setMessageVisible(true);
    };

    return (
        <>
            <div style={{ width: "100%" }} className="table-list">
                <h2 style={{ fontWeight: "bolder", fontSize: "2rem" }}>Lägg till Påstående</h2>
                <Divider />
                <Form form={form} {...layout} name="nest-messages" onFinish={onFinish}>
                    <Form.Item name="category" label="Kategori">
                        <Select placeholder="Välj en kategori" onChange={onCategoryChange} allowClear style={{ width: 498 }}>
                            {categories.map((x) => (
                                <Option value={x.categoryEnum} key={x.id}>
                                    {convertToSwedish(x.categoryEnum)}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item label="Beskrivning" name="description" value={description} onChange={onHandleDescriptionChange}>
                        <TextArea rows={4} style={{ width: 498 }} />
                    </Form.Item>

                    <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
                        <Button type="primary" htmlType="submit">
                            Spara
                        </Button>
                    </Form.Item>
                </Form>
            </div>
            <div>{visible ? <Alert message={message} type="success" closable afterClose={handleCloseMessage} /> : null}</div>
        </>
    );
};

export default AddStatement;
