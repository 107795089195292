import "../../App.css";
import { Route, Routes, useLocation } from "react-router-dom";
import Login from "../Login/Login";
import Navbar from "../Navbar/Navbar";
import StudentList from "../Student/StudentList";
import Dashboard from "../Dashboard/Dashboard";
import AddTechnique from "../Technique/AddTechnique";
import TechniqueList from "../Technique/TechniqueList";
import UpdateTechnique from "../Technique/UpdateTechnique";
import UpdateStudent from "../Student/UpdateStudent";
import AddStudent from "../Student/AddStudent";
import StatementList from "../Statement/StatementList";
import UpdateStatement from "../Statement/UpdateStatement";
import AddStatement from "../Statement/AddStatement";
import CustomTechniqueList from "../CustomTechnique/CustomTechniqueList";
import ArchiveLinkList from "../ArchiveLink/ArchiveLinkList";
import AddArchiveLink from "../ArchiveLink/AddArchiveLink";
import UpdateArchiveLink from "../ArchiveLink/UpdateArchiveLink";
// import Logout from '../Statement/Logout';
import { Logout } from "../Statement/Logout";
import "antd/dist/antd.css";
import "../Navbar/navbar.css";

function App() {
    const location = useLocation();

    return (
        <div className="layout">
            {location.pathname !== "/" ? <Navbar /> : null}
            <main>
                <Routes>
                    <Route path="/" element={<Login />} />
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/listStudents" element={<StudentList />} />
                    <Route path="/addStudent" element={<AddStudent />} />
                    <Route path="/addTechnique" element={<AddTechnique />} />
                    <Route path="/listTechniques" element={<TechniqueList />} />
                    <Route path="/updateTechnique/:id" element={<UpdateTechnique />} />
                    <Route path="/updateStudent/:id" element={<UpdateStudent />} />
                    <Route path="/listStatements" element={<StatementList />} />
                    <Route path="/updateStatement/:id" element={<UpdateStatement />} />
                    <Route path="/addStatement" element={<AddStatement />} />
                    <Route path="/logout" element={<Logout />} />
                    <Route path="/listCustomTechniques" element={<CustomTechniqueList />} />
                    <Route path="/listArchiveLinks" element={<ArchiveLinkList />} />
                    <Route path="/addArchiveLink" element={<AddArchiveLink />} />
                    <Route path="/updateArchiveLink/:id" element={<UpdateArchiveLink />} />
                </Routes>
            </main>
        </div>
    );
}

export default App;
