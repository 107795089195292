import { useState, useRef } from "react";
import { useEffect } from "react";
import { Table, Space, Popconfirm, Button, Input, Divider, Row, Col } from "antd";
import { dataApi } from "../../api/api";
import { useNavigate } from "react-router-dom";
import { SearchOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import "./Statement.css";
import { convertToSwedish } from "../../shared/SharedMethods";

const StatementList = () => {
    const [statements, setStatements] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        loadStatements();
    }, []);

    const loadStatements = async () => {
        var response = await dataApi.getAllStatements();

        response.data.forEach((element) => {
            element.categoryEnum = convertToSwedish(element.categoryEnum);
        });

        setStatements(response.data);
    };

    const handleUpdate = (e) => {
        navigate(`/updateStatement/${e.id}`);
    };

    const handleDelete = async (e) => {
        var response = await dataApi.deleteStatement(e.id);
        console.log(response);
        loadStatements();
    };
    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const searchInput = useRef(null);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText("");
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div
                style={{
                    padding: 8,
                }}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: "block",
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({
                                closeDropdown: false,
                            });
                            setSearchText(selectedKeys[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? "#1890ff" : undefined,
                }}
            />
        ),
        onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: "#ffc069",
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ""}
                />
            ) : (
                text
            ),
    });

    const columns = [
        {
            title: "Kategori",
            dataIndex: "categoryEnum",
            key: "categoryEnum",
            ...getColumnSearchProps("categoryEnum"),
            sorter: (a, b) => {
                if (a.categoryEnum < b.categoryEnum) {
                    return -1;
                }
                if (a.categoryEnum > b.categoryEnum) {
                    return 1;
                }
                return 0;
            },
            sortDirections: ["descend", "ascend"],
        },
        {
            title: "Beskrivning",
            dataIndex: "description",
            key: "description",
            ...getColumnSearchProps("description"),
        },
        {
            title: "Hantera",
            dataIndex: "actions",
            width: "5%",
            render: (_, record) => (
                <Row gutter={16} justify="space-between">
                    <Col>
                        <EditOutlined style={{ color: "#045C94" }} onClick={() => handleUpdate(record)} />
                    </Col>
                    <Col>
                        <Popconfirm title="Är du säker?" okText="Ja" cancelText="Nej" onConfirm={() => handleDelete(record)}>
                            <DeleteOutlined style={{ color: "#EB3E1B" }} />
                        </Popconfirm>
                    </Col>
                </Row>
            ),
        },
    ];

    return (
        <>
            <div style={{ width: "100%" }} className="table-list">
                <Row justify="space-between" align="middle">
                    <Col>
                        <h1 style={{ fontWeight: "bolder", fontSize: "2rem" }}>Påstående</h1>
                    </Col>
                    <Col>
                        <Button
                            className="add-btn"
                            type="primary"
                            onClick={() => {
                                navigate("/addStatement");
                            }}
                        >
                            Lägg till
                        </Button>
                    </Col>
                </Row>

                <Divider />
                <Table columns={columns} dataSource={statements} />
            </div>
        </>
    );
};

export default StatementList;
