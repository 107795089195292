import { Button, Form, Input, Alert, Row, Col, Divider } from "antd";
import { useState } from "react";
import { dataApi } from "../../api/api";

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};

const AddStudent = () => {
    const [form] = Form.useForm();
    const [visible, setMessageVisible] = useState(false);
    const [message, setMessage] = useState("");

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [pinCode, setPinCode] = useState("");
    const [goal, setGoal] = useState("");
    const [reward, setReward] = useState("");

    const onHandleNameChange = (e) => {
        setName(e.target.value);
    };
    const onHandleEmailChange = (e) => {
        setEmail(e.target.value);
    };
    const onHandlePasswordChange = (e) => {
        setPassword(e.target.value);
    };
    const onHandlePinCodeChange = (e) => {
        setPinCode(e.target.value);
    };
/*     const onHandleGoalChange = (e) => {
        setGoal(e.target.value);
    };
    const onHandleRewardChange = (e) => {
        setReward(e.target.value);
    }; */

    const resetStates = () => {
        setName("");
        setEmail("");
        setPassword("");
        setPinCode("");
        setGoal("");
        setReward("");
    };

    const onFinish = async () => {
        const student = {
            name,
            email,
            password,
            pinCode,
            goal,
            reward,
        };

        await dataApi.addStudent(student);
        handleSetMessage(student.name);
        form.resetFields();
        resetStates();
    };

    const handleCloseMessage = () => {
        setMessageVisible(false);
    };

    const handleSetMessage = (username) => {
        setMessage(`Student: ${username} är nu sparad!`);
        setMessageVisible(true);
    };

    return (
        <div style={{ width: "100%" }} className="table-list">
            <h2 style={{ fontWeight: "bolder", fontSize: "2rem" }}>Lägg till Student</h2>
            <Divider />
            <Form form={form} name="nest-messages" onFinish={onFinish}>
                <Row gutter={16} justify="center">
                    <Col span={8}>
                        <Form.Item label="Namn" name="name" value={name} onChange={onHandleNameChange} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                            <Input width={"100%"} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="E-post" name="email" value={email} onChange={onHandleEmailChange} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16} justify="center">
                    <Col span={8}>
                        <Form.Item label="Lösenord" name="password" value={password} onChange={onHandlePasswordChange} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Pinkod" name="pinCode " value={pinCode} onChange={onHandlePinCodeChange} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                            <Input placeholder="PIN-koden är till för målsman" />
                        </Form.Item>
                    </Col>
                </Row>
                {/* <Row gutter={16} justify="center">
                    <Col span={8}>
                        <Form.Item label="Mål" name="goal" value={goal} onChange={onHandleGoalChange} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Belöning" name="reward" value={reward} onChange={onHandleRewardChange} labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
                            <Input />
                        </Form.Item>
                    </Col>
                </Row> */}

                <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }} style={{ marginTop: 24 }}>
                    <Button type="primary" htmlType="submit">
                        Spara
                    </Button>
                </Form.Item>
            </Form>
            <div>{visible ? <Alert message={message} type="success" closable afterClose={handleCloseMessage} /> : null}</div>
        </div>
    );
};

export default AddStudent;
