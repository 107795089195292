import React from "react";
import { useEffect, useState } from "react";
import { dataApi } from "../../api/api";
import styled from "styled-components";
import { convertToSwedish, categoryColor } from "../../shared/SharedMethods";
import { Col, Divider, Row } from "antd";

const Dashboard = () => {
    const [students, setStudents] = useState([]);
    const [statements, setStatements] = useState([]);
    useEffect(() => {
        loadStudents();
        loadStatements();
    }, []);
    const loadStudents = async () => {
        let res = await dataApi.getAllStudents();
        setStudents((student) => [...student, res.data]);
    };
    const loadStatements = async () => {
        var response = await dataApi.getAllStatements();
        setStatements((statement) => [...statement, response.data]);
    };
    const Header = styled.h2`
        font-size: 3rem;
        font-weight: bold;
    `;

    if (students.length !== 0 && statements.length !== 0)
        return (
            <>
                <div className="dashbaord-wrapper">
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <Header>Dashboard</Header>
                        <Header>Antal Studenter: {students[0].length} Personer</Header>
                    </div>
                    <Divider />
                    <div style={{ marginTop: 16 }}>
                        <h1 style={{ fontWeight: "bolder" }}>Kategori</h1>
                        <div style={{ marginTop: 16 }}>
                            <Row gutter={[16, 16]}>
                                {statements[0]
                                    .filter((v, i, a) => a.findIndex((v2) => v2.categoryEnum === v.categoryEnum) === i)
                                    .map((r, i) => (
                                        <Col span={4}>
                                            <div className="category-card" style={{ backgroundColor: `${categoryColor(r.categoryEnum)}` }}>
                                                <h3>{convertToSwedish(r.categoryEnum)}</h3>
                                                <Divider style={{ margin: "8px 0" }} />
                                                <p className="statement">{statements[0].filter((statement) => statement.categoryEnum === r.categoryEnum).length}</p>
                                                <p style={{ color: "#14213D" }}>Påstående</p>
                                            </div>
                                        </Col>
                                    ))}
                            </Row>
                        </div>
                    </div>
                </div>
            </>
        );
};

export default Dashboard;
