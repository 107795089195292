import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

export const Logout = ()=>{
    
    const navigate = useNavigate();

    // const consoleLog = () =>{
    //     localStorage.clear();
    //     navigate(`/`);
    //     console.log("Clear")
    //   }

    useEffect(() => {
        // consoleLog();
        localStorage.clear();
        navigate(`/`);
      }, []);

}
